import React, {Component} from 'react';

export class Welcome extends Component {

    render() {
        return (<div> WELCOME</div>)
    }
}

export default Welcome
