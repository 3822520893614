const STYLES = {
    WRAPPER_STYLES: {
        padding: 25,
        width: '100%',
        maxWidth: 800,
        margin: '10px auto',
        backgroundColor: 'white',
        minHeight: 400,
        border: ' 1px solid #e4e7ea',
        borderRadius: 10,
        display: 'block'
    }
}

export default STYLES
