import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoadTracker from './load/LoadTracker';
import Welcome from './Welcome';

function App(props) {
  return (
    <Router>
      <div>
        <Route exact path="/" component={Welcome} />
        <Route path="/:id" component={LoadTracker} />
      </div>
    </Router>
  );
}

export default App
