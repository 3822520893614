import React, { Component } from "react";
import Header from "./Header";
import SearchBox from "./SearchBox";
import ResponseBox from "./ResponseBox";
import { getBusiness } from "./service";
import _get from "lodash/get";
import STYLES from "./STYLES";

export class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadStatus: null,
            loading: true,
            business: {}
        };
    }

    async componentDidMount() {
        const businessKey = _get(this, "props.match.params.id");
        try {
            const business = await getBusiness(businessKey);
            this.setState({ business });
        } catch (e) {}
    }

    successResponse = loadStatus => {
        this.setState({ loadStatus, loading: false });
        const business = this.state.business || {};

        if (loadStatus && loadStatus.busalias) {
            this.setState({
                business: { ...business, ...loadStatus.busalias }
            });
        }
    };

    render() {
        const loading = this.state.loading;
        const business = this.state.business;

        return (
            <div className="app">
                <div className="" style={STYLES.WRAPPER_STYLES}>
                    {business.id && !loading && (
                        <Header business={business} loading={loading} />
                    )}
                    {business.id && (
                        <SearchBox
                            business={business}
                            successResponse={this.successResponse}
                        />
                    )}
                    {loading ? (
                        <h4 className="text-center p-4">Searching...</h4>
                    ) : (
                        <ResponseBox loadStatus={this.state.loadStatus} />
                    )}
                </div>
            </div>
        );
    }
}

export default App;
