const moment = require('moment-timezone');

moment.createFromInputFallback = function(config) {
    config._d = new Date(config._i);
};

export function formatRawDate(date) {
    if (moment(date).isValid()) {
        return moment(date).format('MM/D/YYYY');
    } else {
        return null
    }
}

export function formatDate(date) {
    if (moment(date).isValid()) {
        return moment(date).format('MMM Do YYYY');
    } else {
        return 'N/A'
    }
}

export function formatDateTime(date) {
    if (moment(date).isValid()) {
        return moment(date).format('MMM Do YYYY @ hh:mm A');
    } else {
        return 'N/A'
    }
}

export function formatMonthYear(date) {
    if (moment(date).isValid()) {
        return moment(date).format('MMM YYYY');
    } else {
        return ''
    }
}

export function getMaxDateYears(value) {
    return moment().subtract(value, 'years')
}

export function getYear(date) {
    if (moment(date).isValid()) {
        return moment(date).format('YYYY');
    } else {
        return null
    }
}

export function getMonth(date) {
    if (moment(date).isValid()) {
        return moment(date).format('MM');
    } else {
        return null
    }
}

export function getDay(date) {
    if (moment(date).isValid()) {
        return moment(date).format('D');
    } else {
        return null
    }
}

export function getHours(date) {
    if (moment(date).isValid()) {
        return moment(date).format('HH');
    } else {
        return null
    }
}

export function getMinutes(date) {
    if (moment(date).isValid()) {
        return moment(date).format('mm');
    } else {
        return null
    }
}

export function getAllDates(start, end) {

    function __getAllDates(date) {
        return {label: formatRawDate(date)}
    };

    var previous = moment(start).subtract(1, 'days');
    var last = moment(end).add(1, 'days');
    var dates = [__getAllDates(previous), __getAllDates(start)];
    var currDate = moment(start).startOf('day');
    var lastDate = moment(end).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(__getAllDates(currDate.clone().toDate()));
    }
    dates.push(__getAllDates(end));
    dates.push(__getAllDates(last));

    return dates;
}

export function getCronExpression(date, time) {
    const dateTime = moment(`${date} ${time}`);
    const minutes = getMinutes(dateTime);
    const hours = getHours(dateTime);
    const day = getDay(dateTime);
    const month = getMonth(dateTime);

    return `${minutes} ${hours} ${day} ${month} *`;
}

export function formatCronDate(cronformat) {
    if (cronformat === '1 1/2 * * *') {
        return 'Every two hours'
    }
    cronformat = cronformat.replace('*', getYear(new Date()));
    const cronArray = cronformat.split(' ').reverse();
    return formatDateTime(cronArray);
}

export function getAge(date) {
    if (moment(date).isValid()) {
        const isoDate = new Date(date).toISOString();
        const age = moment().diff(moment(isoDate), 'years', true);
        return age;
    }
}

export function getAdultYears() {
    const last = moment().subtract(18, 'years');
    const currentYear = moment(last, "DD/MM/YYYY").year();
    let allYears = [];

    for (let i = 0; i < 50; i++) {
        allYears.push(currentYear - i);
    }
    return allYears;
}
