import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

export class Header extends Component {

    render() {
      const nameStyles = {
        fontWeight: 800
      }
      const business = this.props.business || {};
      const src = business.logo || 'https://osrit.com/images/logo_s.png';
      const name = business.name;
      const addressLine1 = business.line1;
      const addressLine2 = `${business.city} ${business.state}-${business.zip}`;

      return  (<Row style={{ borderBottom: '1px solid grey', paddingBottom: 10 }}>
                <Col xs="4">
                  <img src={src} width="30" height="30" alt="" />
                </Col>
                <Col xs="8" className="text-right">
                  <div style={ nameStyles }>{name}</div>
                  {addressLine1 && <div style={ nameStyles }>{addressLine1}</div>}
                  {addressLine2 && <div style={ nameStyles }>{addressLine2}</div>}
                </Col>
              </Row>
              )
    }
}

export default Header;
