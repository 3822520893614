import React, {Component} from 'react';
import {Card, Alert} from 'reactstrap';
import queryString from 'query-string';
import {formatDateTime} from '../utils/momentHelpers';

const NoStatus = ({data}) => {
    return (<div style={{
            padding: 10
        }}>
        <Alert color="warning">
            No status is available.
        </Alert>
    </div>)
}

const LoadStatus = ({data}) => {
    return (<div style={{
            padding: 10,
            borderBottom: '1px solid #d8dce0'
        }}>
        <div>Date:- {formatDateTime(data.addedon)}</div>
        {data.loadstatus && <div style={{ textTransform: 'capitalize' }}>Status:- {data.loadstatus}</div>}
        <div>Detail:- {data.detail}</div>
    </div>)
}

export class ResponseBox extends Component {

    constructor(props) {
        super(props)
        const parsed = queryString.parse(window.location.search)
        this.state = {
            loadNo: parsed.loadNo,
            loading: false,
            notFound: false
        }
    }

    scrollToStatus = () => {
        setTimeout(() => {
            window.scrollTo({top: 400, left: 100, behavior: 'smooth'})
        }, 100)
    }

    render() {
        if (!this.props.loadStatus) {
            return null;
        }
        if (!this.props.loadStatus.loadnumber) {
            return <NoStatus/>;
        }
        const load = this.props.loadStatus || {};

        this.scrollToStatus()
        return (<Card style={{
                padding: 10,
                marginTop: 25
            }}>
            <h3 style={{
                    marginTop: 20,
                    textAlign: 'center'
                }}>CURRENT STATUS</h3>
            <div>Load No:- {load.loadnumber}</div>
            <div style={{ textTransform: 'capitalize' }}>Status:- {load.loadstatus}</div>
            <div>PickUp:- {`${load.pick} @ ${formatDateTime(load.pickDate)}`}</div>
            <div>Delivery:- {`${load.drop} @ ${formatDateTime(load.dropDate)}`}</div>
            <h4 style={{
                    paddingTop: 25
                }}>History</h4>
            {load.updates && load.updates.map((item, i) => <LoadStatus data={item} key={`update-${i}`}/>)}
        </Card>)
    }
}

export default ResponseBox
