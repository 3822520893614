import React, { Component } from "react";
import {
    Form,
    FormGroup,
    FormText,
    FormFeedback,
    Label,
    Input,
    Button
} from "reactstrap";
import queryString from "query-string";
import { getStatus } from "./service";

export class SearchBox extends Component {
    constructor(props) {
        super(props);
        const parsed = queryString.parse(window.location.search);
        this.state = {
            loadNo: parsed.loadNo,
            loading: false,
            notFound: false
        };
    }

    componentDidMount() {
        if (this.state.loadNo) {
            this.searchLoadStatus();
        }
    }

    handleFocus = e => {
        e.preventDefault();
        this.setState({ notFound: false });
    };

    handleClick = e => {
        e.preventDefault();
        this.searchLoadStatus();
    };

    searchLoadStatus = e => {
        const loadNo = this.state.loadNo;
        const businessid = this.props.business.id;
        this.props.successResponse(null);
        this.setState({ loading: true, notFound: false });

        getStatus(loadNo, businessid)
            .then(response => {
                this.setState({
                    loading: false,
                    notFound: !response.loadnumber
                });
                return this.props.successResponse(response);
            })
            .catch(err => {
                this.setState({ loading: false, notFound: true });
            });
    };

    render() {
        const { loading, loadNo, notFound } = this.state;
        const isInvalidLength = loadNo ? loadNo.length < 4 : false;
        const isInputInvald = notFound || isInvalidLength;

        return (
            <div id="contentWrapper">
                <h1
                    style={{
                        marginTop: 15,
                        textAlign: "center",
                        padding: 20
                    }}
                >
                    TRACK LOAD
                </h1>
                <Form
                    style={{
                        maxWidth: 400,
                        margin: "55px auto "
                    }}
                >
                    <FormGroup>
                        <Label className="sr-only" for="loadNo">
                            Enter load #
                        </Label>
                        <Input
                            invalid={isInputInvald}
                            disabled={loading}
                            autoComplete="off"
                            type="text"
                            placeholder="Enter load number"
                            defaultValue={this.state.loadNo}
                            onChange={e =>
                                this.setState({ loadNo: e.target.value })
                            }
                            onFocus={this.handleFocus}
                        />
                        <FormText>
                            Enter load number to view current freight status.
                        </FormText>
                        {isInvalidLength && (
                            <FormFeedback tooltip={true}>
                                Load number should atleast four digit.
                            </FormFeedback>
                        )}
                        {notFound && (
                            <FormFeedback>
                                Not able to track Load# {this.state.loadNo}
                            </FormFeedback>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Button
                            style={{
                                marginTop: 20
                            }}
                            disabled={
                                !this.state.loadNo || isInputInvald || loading
                            }
                            onClick={this.handleClick}
                            color="primary"
                            size="sm"
                            block={true}
                        >
                            {loading ? "Searching..." : "SEARCH"}
                        </Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

export default SearchBox;
